<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img class="arvas-logo" :src="arvasLogo"/>
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">Arvas</h2> -->
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center pl-0 pr-0">
        <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"> -->
        <b-img fluid :src="bigImage"/>
        <!-- </div> -->
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto login-root">
          <div class="d-flex justify-content-center mb-3">
            <b-img class="oys-logo" :src="oysLogo"/>
          </div>
          <!-- <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Öğretmenevi Yönetim Sistemi
          </b-card-title> -->

          <!-- divider -->
          <hr/>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- Firma Kodu -->
              <!--              <b-form-group>-->
              <!--                <div class="d-flex justify-content-between">-->
              <!--                  <label for="login-password">Firma Kodu</label>-->
              <!--                </div>-->
              <!--                <validation-provider #default="{ errors }">-->
              <!--                  <b-form-input-->
              <!--                    v-model="kurumKodu"-->
              <!--                    :state="errors.length > 0 ? false : null"-->
              <!--                    placeholder="Firma Kodu"-->
              <!--                    ref="focusMe"-->
              <!--                  />-->
              <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                </validation-provider>-->
              <!--              </b-form-group>-->
              <!-- Kullanıcı Adı -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Kullanıcı Adı</label>
                </div>
                <validation-provider #default="{ errors }">
                  <b-form-input
                      ref="focusMe"
                      v-model="user"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Kullanıcı Adı"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Şifre -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Şifre</label>
                </div>
                <validation-provider #default="{ errors }">
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="pwd"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  name="checkbox-1"
                  v-model="beniHatirla"
                >
                  Beni Hatırla
                </b-form-checkbox>
              </b-form-group> -->
              <!-- submit buttons -->
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  type="submit"
                  variant="info"
                  block
                  @click="logIn()"
              >
                Oturum Aç
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  data() {
    return {
      beniHatirla: '',
      pwd: '',
      kurumKodu: '',
      user: '',
      oysLogo: require('@/assets/images/oys/logo/oys-3.png'),
      arvasLogo: require('@/assets/images/oys/logo/arvas.png'),
      bigImage: require('@/assets/images/oys/login/macos.jpg'),
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    logIn() {
      this.$store
          .dispatch('logIn', {
            // firmaKodu: this.kurumKodu,
            user: this.user,
            pwd: this.pwd,
          })
          .then((res, position = 'bottom-right') => {
            this.$router.push('/')
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Sisteme Hoşgeldiniz',
                    text: 'Öğretmenevi Yönetim Sistemi',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: err.responseJSON.errMsg,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
  created() {
  },
  mounted() {
    this.$refs.focusMe.focus()
  },
}
</script>

<style scoped>
.auth-inner {
  overflow-y: hidden !important;
}

.img-fluid {
  max-width: 120%;
  height: auto;
}

.oys-logo {
  width: 230px !important;
}

.arvas-logo {
  width: 150px !important;
  background-color: transparent !important;
}

.login-root {
  margin-bottom: 110px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
